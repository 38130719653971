/* eslint-disable react-hooks/exhaustive-deps */
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";

import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import { INQUIRY_STATUS, TRANSFER_SORT_BY } from "helpers/constans";
import moment from "moment";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import { IPagination } from "api/types/general";
import classnames from "classnames";
import CardSummary from "pages/Media/Components/CardSummary";
import TableContainer from "components/Common/TableContainer";
import DatePickerCustom from "components/Common/DatePickerCustom";
import inquiryApi from "api/inquiryApi";
import { ROUTES } from "routes/allRoutes";

const AcceptFileType = [
  "image/jpg",
  "application/pdf",
  "image/png",
  "image/jpeg",
];

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const ServiceInquiry = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const location = useLocation();

  const SERVICE_TYPE_OPTIONS_LANG = [
    {
      label: "inquiry.AllData",
      value: "",
    },
    ...INQUIRY_STATUS,
  ]?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const SORT_BY_OPTIONS = TRANSFER_SORT_BY?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [formatSearch, setFormatSearch] = useState<Option | null>({
    label: t("inquiry.AllData"),
    value: "",
  });

  const [sortBy, setSortBy] = useState<Option | null>({
    label: t("transfer.sort_by.newest"),
    value: "DESC",
  });

  const [loading, setLoading] = useState(false);
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 10,
    created_from: moment().subtract(90, "days").format("YYYY-MM-DD"),
    created_to: moment().format("YYYY-MM-DD"),
    sort_by: "",
    status: "",
    // tab: "1"
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [summaries, setSummaries] = useState<any>();
  const defaultRangeDate = [
    queryParams?.created_from
      ? moment(queryParams?.created_from || "", "Y-MM-DD").toDate()
      : null,
    queryParams?.created_to
      ? moment(queryParams?.created_to || "", "Y-MM-DD").toDate()
      : null,
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;
  const [isChangeKeyword, setIsChangeKeyword] = useState<boolean>(false);

  const columns = useMemo(
    () => [
      {
        Header: t("inquiry.STT"),
        thClass: "text-center",
        Cell: (cell: any) => (
          <div
            className="text-center"
            style={{
              width: "60px",
            }}
          >
            <span>
              {pagination.total -
                pagination.page_size * (pagination.page - 1) -
                cell?.row?.index}
            </span>
          </div>
        ),
      },
      //   {
      //     Header: t("inquiry.Service"),
      //     accessor: "service_type",
      //     thClass: "text-center",
      //     Cell: (cell: any) => {
      //       let tagName: string = "";
      //       const servicesKeyType = new Map(
      //         TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS.map((i) => [
      //           i.value,
      //           i.label,
      //         ])
      //       );
      //       tagName += `${`<div class='tag-service mx-auto bg-${servicesKeyType.get(
      //         cell?.value.toString()
      //       )}'  >${
      //         servicesKeyType.get(cell?.value.toString()) === "Presscon"
      //           ? "Prc"
      //           : servicesKeyType.get(cell?.value.toString())
      //       } </div>`}`;
      //       return (
      //         <div
      //           className="mx-auto"
      //           style={{
      //             width: "80px",
      //           }}
      //           dangerouslySetInnerHTML={{ __html: tagName }}
      //         />
      //       );
      //     },
      //   },
      {
        Header: t("inquiry.Inquiry_type"),
        accessor: "inquiry_category",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value?.name ? cell?.value?.name : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.detail"),
        accessor: "description",
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <div
              className="w-100 text-center h-100"
              style={{
                minWidth: "150px",
              }}
            >
              {cell?.value ? (
                <p
                  className="text-decoration-underline cursor-pointer mb-0"
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "150px",
                  }}
                >
                  {cell?.row?.original?.inquiry_category?.code ===
                  "domain_management"
                    ? `${t("inquiry.FORM.DOMAIN.LABEL")}: ${
                        cell?.row?.original?.details?.domain_purchaser
                      }`
                    : cell.value}
                </p>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        Header: t("cms_media.media_name"),
        accessor: "company",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value?.name ? cell?.value?.name : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.Author"),
        accessor: "created_by",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "150px",
            }}
          >
            {cell?.value?.name || "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.Created_At"),
        accessor: "created_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "150px",
            }}
          >
            {moment(cell.value).isValid()
              ? moment(cell.value).format("YYYY.MM.DD HH:mm:ss")
              : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.Status"),
        accessor: "status",
        thClass: "text-center",
        Cell: (cell: any) => {
          const statusOptions = new Map(
            INQUIRY_STATUS.map((i) => [i.value, i.label])
          );
          return (
            <p className="mb-0" style={{ width: "100px" }}>
              {t(statusOptions.get(cell?.value.toString()) || "-")}
            </p>
          );
        },
      },
      {
        Header: t("inquiry.Completed_At"),
        accessor: "reply_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "150px",
            }}
          >
            {moment(cell.value).isValid()
              ? moment(cell.value).format("YYYY.MM.DD HH:mm:ss")
              : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.Deduction_points"),
        accessor: "deduction_point",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p className="mb-0" style={{ width: "100px" }}>
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.CS_Manager"),
        accessor: "managed_by",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p className="mb-0" style={{ width: "100px" }}>
            {cell?.value?.name || "-"}
          </p>
        ),
      },
    ],
    [i18n?.language, userPermissions, pagination]
  );

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      page: 1,
      search: isChangeKeyword ? keyword : queryParams?.search,
      status: formatSearch?.value,
      sort_by: sortBy?.value ? sortBy?.value : queryParams?.sort_by,
      created_from: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      created_to: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    });
  };

  const getSummaries = async () => {
    const [err, result] = await catchAsync(
      inquiryApi.getSummaries({ status: "" }),
      setLoadingSummaries
    );
    if (err) {
      toast.error(err);
      return;
    }
    setSummaries(result?.data);
  };

  const getList = async () => {
    setLoading(true);
    const requestBody = {
      ...queryParams,
      status: queryParams?.status + "" || "",
    };

    const [err, result] = await catchAsync(
      inquiryApi.getList(requestBody),
      setLoading
    );
    if (err) {
      toast.error(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setFormatSearch({
      label: t("All Type"),
      value: "",
    });
    setDateSearch([moment().subtract(90, "days").toDate(), moment().toDate()]);
  };

  useEffect(() => {
    searchData();
  }, [formatSearch, sortBy]);

  useEffect(() => {
    getList();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location?.search]);

  useEffect(() => {
    // document.title = `Super Admin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    getSummaries();
    // getTotalWaiting();
  }, []);

  return (
    <React.Fragment>
      <div className="">
        {/* <BreadCrumb title={t("inquiry.menu_title")} /> */}
        {/* <div className="d-flex align-items-center gap-2 mb-3 justify-content-between">
          <div></div>
          <button
            type="button"
            className="btn btn-primary me-1"
            onClick={() => navigate(ROUTES.INQUIRY_MANAGEMENT.INQUIRY_HOMEPAGE)}
          >
            {`${t("inquiry.homepage_inquiry")} `}
            {loadingTotalWaiting ? <Spinner size="sm"></Spinner> : totalWaiting}
          </button>
        </div> */}
        <Row>
          <Col lg={12}>
            <Card id="leadsList">
              <CardHeader className="border-0">
                <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2">
                  <CardSummary
                    title="inquiry.Awaiting_Reply"
                    value={summaries?.[0] || 0}
                    isLoading={loadingSummaries}
                  />

                  <CardSummary
                    title="inquiry.replying"
                    value={summaries?.[1] || 0}
                    isLoading={loadingSummaries}
                  />

                  <CardSummary
                    title="inquiry.answer_complete"
                    value={summaries?.[2] || 0}
                    isLoading={loadingSummaries}
                  />
                </div>
                <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2">
                  <div className="w-100">
                    <div className="align-items-end">
                      <Row>
                        <Col
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className="mt-2 mt-md-0 mb-2 mb-md-0"
                        >
                          <div
                            className="d-flex align-items-center date-picker-wrapper-custom"
                            style={{ gap: "40px" }}
                          >
                            <div className="fw-bold text-nowrap">
                              {t("inquiry.period")}
                            </div>
                            <div style={{ width: "300px" }}>
                              <DatePickerCustom
                                placeholder={`${t("A From Range")} - ${t(
                                  "A To Range"
                                )}`}
                                startDate={startDate || null}
                                endDate={endDate || null}
                                disabled={false}
                                isClearable={true}
                                onChangePicker={handleChangePicker}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="g-4 align-items-center mt-0 mt-md-2">
                        <Col sm={12} md={12} lg={4} className="mt-3 mt-md-2">
                          <div
                            className="d-flex align-items-center "
                            style={{ gap: "40px" }}
                          >
                            <div className="fw-bold text-nowrap">
                              {t("inquiry.search")}
                            </div>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("inquiry.PlaceholderSearch")}`}
                              value={keyword}
                              onChange={(e) => {
                                setIsChangeKeyword(true);
                                setKeyword(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          md={12}
                          lg={3}
                          className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2"
                        >
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-1"
                              onClick={searchData}
                              disabled={loading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>
                              {t("Button Reset")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0"
                    role="tablist"
                  >
                    {SERVICE_TYPE_OPTIONS_LANG.map((item, index) => (
                      <NavItem key={index}>
                        <NavLink
                          className={`cursor-pointer ${classnames({
                            active: formatSearch?.value === item?.value,
                          })}`}
                          onClick={() => {
                            setFormatSearch(item);
                          }}
                        >
                          {t(item?.label)}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <LabelWrapper isShow={false} className="w-25">
                    <DropdownOption
                      name="sort_by"
                      dataList={SORT_BY_OPTIONS || []}
                      className="search-filter-category-type"
                      classNamePrefix="name-prefix"
                      initialValue={sortBy || null}
                      onChangeSelect={(value: Option) => setSortBy(value)}
                      // isHasOptionAll={false}
                      // optionAll={{
                      //   label: t("inquiry.AllData"),
                      //   value: "",
                      // }}
                    />
                  </LabelWrapper>
                </div>
              </CardHeader>
              <CardBody className="pt-3">
                <div>
                  <TableContainer
                    className="custom-header-css"
                    divClass="table-responsive table-card"
                    tableClass="align-middle text-center"
                    theadClass="table-light text-center"
                    columns={columns}
                    data={rows || []}
                    customPageSize={pagination?.page_size}
                    customPageIndex={pagination?.page - 1}
                    totalRecords={pagination?.total}
                    customPageCount={Math.ceil(
                      pagination?.total / pagination?.page_size
                    )}
                    handleChangePage={handleChangePage}
                    manualSorting={true}
                    isLoading={loading}
                    scrollbarOs={false}
                    onClickRow={(_, event: any) => {
                      navigate(
                        `${ROUTES.INQUIRY_MANAGEMENT.INQUIRY}/service/${event?.original?.id}`
                      );
                    }}
                  />
                </div>
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ServiceInquiry;
