import DropdownOption from "components/Common/DropdownOption";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import {
  INQUIRY_VIA_PHONE_OPTIONS,
  PUBLIC_INQUIRY_LANG,
} from "helpers/constans";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import ModalRecordRegistration from "./ModalRecordRegistration";
import { catchAsync } from "helpers/general";
import { toast, ToastContainer } from "react-toastify";
import inquiryApi from "api/inquiryApi";
import ModalConfirmDelete from "./ModalConfirmDelete";

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const CustomerInquiryDetail = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<any>();
  const [status, setStatus] = useState(
    data?.status || INQUIRY_VIA_PHONE_OPTIONS[1].value || ""
  );

  const [loading, setLoading] = useState(false);
  const [memo, setMemo] = useState(data?.content);
  const [open, setOpen] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const STATUS_OPTIONS = INQUIRY_VIA_PHONE_OPTIONS.filter(
    (i) => !!i.value
  )?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const handleDelete = async () => {
    const [error, result] = await catchAsync(
      inquiryApi.deleteInquiriesPhone(data?.id)
    );
    if (error) {
      toast.error(error);
      return;
    }
    setModalConfirm(false);
    toast.success(result.message);
    navigate(-1);
    return;
  };

  const handleChangeText = (event: any) => {
    setMemo(event?.target?.value);
  };

  const handleUpdate = (params: any) => async () => {
    const [error, result] = await catchAsync(
      inquiryApi.updateInquiriesPhone(data?.id, params),
      setLoading
    );
    if (error) {
      toast.error(error);
      return;
    }

    toast.success(result.message);
    // refesh(result?.data);
    setData(result?.data);
    return;
  };

  const getInquiryDetail = async (id: any) => {
    const [err, result] = await catchAsync(inquiryApi.getPhoneDetail(id, {}));
    if (err) {
      toast.error(err);
      return;
    }
    setData(result?.data);
  };

  useEffect(() => {
    if (id) {
      getInquiryDetail(id);
    }
  }, [id]);

  useEffect(() => {
    // document.title = `Super Admin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex align-items-center gap-2 mb-3">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            >
              <i className={"bx bx-arrow-back fs-22"}></i>
            </button>
            <h4 className="mb-sm-0">
              {t("inquiry.menu_title")} | {t("inquiry.detail")}
            </h4>

            <div style={{ marginLeft: "auto" }} className="d-flex gap-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setOpen(true)}
              >
                {t("inquiry.btn.edit_info")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setModalConfirm(true)}
              >
                {t("inquiry.btn.delete")}
              </button>
            </div>
          </div>
          <Card id="leadsList">
            <CardBody className="pt-3">
              <Row style={{ gap: "10px 0" }} className="align-items-center">
                <Col sm={12} md={6} lg={3}>
                  <div
                    className="d-flex align-items-center "
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "100px" }}
                    >
                      {t("inquiry.Inquiry_type")}
                    </div>
                    <div>
                      {data?.inquiry_type
                        ? t(PUBLIC_INQUIRY_LANG?.[data?.inquiry_type])
                        : "-"}
                    </div>
                  </div>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <div
                    className="d-flex align-items-center "
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "100px" }}
                    >
                      {t("inquiry.media_name")}
                    </div>
                    <div>{data?.company_name || "-"}</div>
                  </div>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <div
                    className="d-flex align-items-center "
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "100px" }}
                    >
                      {t("inquiry.contact_person")}
                    </div>
                    <div>{data?.contact_name || "-"}</div>
                  </div>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <div
                    className="d-flex align-items-center "
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "50px" }}
                    >
                      {t("inquiry.email")}
                    </div>
                    <div>{data?.email || "-"}</div>
                  </div>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <div
                    className="d-flex align-items-center "
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "100px" }}
                    >
                      {t("inquiry.phoneNumber")}
                    </div>
                    <div>{data?.phone_number || "-"}</div>
                  </div>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <div
                    className="d-flex align-items-center "
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "100px" }}
                    >
                      {t("inquiry.created_date")}
                    </div>
                    <div>
                      {data?.created_at
                        ? moment(data?.created_at).format("YYYY.MM.DD")
                        : ""}
                    </div>
                  </div>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <div
                    className="d-flex align-items-center "
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "100px" }}
                    >
                      {t("inquiry.contacted_date")}
                    </div>
                    <div>
                      {data?.contacted_at
                        ? moment(data?.contacted_at).format("YYYY.MM.DD")
                        : "-"}
                    </div>
                  </div>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <div
                    className="d-flex align-items-center "
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "50px" }}
                    >
                      {t("inquiry.Status")}
                    </div>
                    <div
                      style={{ width: "calc(100% - 60px)" }}
                      className="d-flex gap-2 align-items-center"
                    >
                      <div className="w-100">
                        <DropdownOption
                          name="status"
                          dataList={STATUS_OPTIONS}
                          className="search-filter-category-type"
                          classNamePrefix="name-prefix"
                          initialValue={STATUS_OPTIONS?.find(
                            (i) => i?.value == status
                          )}
                          onChangeSelect={(e: any) => {
                            if (e) {
                              setStatus(e?.value);
                            }
                          }}
                          labelTop={""}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleUpdate({ status: +status })}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {/* {loading ? <Spinner size="sm"></Spinner> : null} */}
                        {t("inquiry.btn.change")}
                      </button>
                    </div>
                  </div>
                </Col>

                <Col sm={12} md={12} lg={12}>
                  <div>
                    <div
                      className="fw-bold text-nowrap"
                      style={{ width: "100px" }}
                    >
                      {t("inquiry.Memo")}
                    </div>
                    <div className="mt-1">
                      <textarea
                        className="form-control"
                        id="textMemo"
                        value={memo}
                        rows={5}
                        onChange={handleChangeText}
                        placeholder={t("inquiry.placeholder")}
                        maxLength={500}
                      />
                      <button
                        type="button"
                        className="btn btn-primary mt-2"
                        onClick={handleUpdate({ content: memo })}
                      >
                        {/* {loading ? <Spinner size="sm"></Spinner> : null} */}
                        {t("inquiry.register")}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <ToastContainer closeButton={false} limit={1} />
        <ModalRecordRegistration
          open={open}
          onClose={() => setOpen(false)}
          refresh={(data: any) => {
            setOpen(false);
            setData(data);
            setStatus(data?.status);
          }}
          data={data}
        />
        <ModalConfirmDelete
          open={modalConfirm}
          onSubmit={handleDelete}
          onClose={() => setModalConfirm(false)}
        />
      </div>
    </React.Fragment>
  );
};

export default CustomerInquiryDetail;
