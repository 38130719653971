import cmsHubMembershipApi from "api/membershipApi";
import DropdownOption from "components/Common/DropdownOption";
import { useFormik } from "formik";
import { BUSSINESS_TEAM, BUSSINESS_TEAM_LANG, LAYOUT_TYPE, MANAGER_ADS_TYPE, COMPANY_TYPE } from "helpers/constans";
import { catchAsync } from "helpers/general";
import moment from "moment";
import DateTimePickerCustom from "pages/CmsHub/Components/DateTimePicker";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FormFeedback, Input, Modal, ModalBody, Nav, NavItem, Spinner, NavLink, TabContent, TabPane } from "reactstrap";
import * as Yup from "yup";
import classnames from "classnames";

interface Props {
  open: boolean;
  onClose: () => void;
  refesh: () => void;
  business: any[];
}
const ModalRegisterPresscon = ({ open, onClose, refesh, business }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tabModal, setTabModal] = useState("1");

  const initialValues = {
    business_team_id: BUSSINESS_TEAM.TEAM_A,
    company_name: "",
    domain: "https://",
    domain_start_date: "",
    domain_end_date: "",
    footer_configs: {
      registration_number: "",
      company_registration_number: "",
      chief_editor: "",
      publisher: "",
      youth_protection_officer: "",
      address: "",
      main_phone: "",
    },
    manager_ads_type: MANAGER_ADS_TYPE.NONE,
    layout_type: LAYOUT_TYPE.NEWS,
    name: "",
    domain_provider: "",
    domain_id: "",
    domain_password: "",
    company_type: COMPANY_TYPE.NEW,
    phone_number: "",
    email: ""
  };

  const createPopupSchema = Yup.object().shape({
    business_team_id: Yup.number().required(),
    company_name: Yup.string().required(t("media.CompanyNameCannotBeEmpty")),
    domain: Yup.string()
      .matches(
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[\w\.-]*)*\/?$|^https?:\/{2}$/,
        t("media.URLFormatIsIncorrect")
      )
      .optional()
      .nullable(),
    domain_start_date: Yup.string().optional().nullable(),
    domain_end_date: Yup.string().optional().nullable(),
    layout_type: Yup.string().required(),
    footer_configs: Yup.object({
      registration_number: Yup.string()
        .when("$company_type", {
          is: COMPANY_TYPE.NEW,
          then: schema => schema.optional().nullable(),
          otherwise: schema => schema.required(t("media.PleaseEnterYourInformation")),
        }),
      chief_editor: Yup.string()
        .when("$company_type", {
          is: COMPANY_TYPE.NEW,
          then: schema => schema.optional().nullable(),
          otherwise: schema => schema.required(t("media.PleaseEnterYourInformation")),
        }),
      publisher: Yup.string()
        .when("$company_type", {
          is: COMPANY_TYPE.NEW,
          then: schema => schema.optional().nullable(),
          otherwise: schema => schema.required(t("media.PleaseEnterYourInformation")),
        }),
      address: Yup.string()
        .when("$company_type", {
          is: COMPANY_TYPE.NEW,
          then: schema => schema.optional().nullable(),
          otherwise: schema => schema.required(t("media.PleaseEnterYourInformation")),
        }),
      main_phone: Yup.string()
        .when("$company_type", {
          is: COMPANY_TYPE.NEW,
          then: schema => schema.optional().nullable(),
          otherwise: schema => schema.required(t("media.PleaseEnterYourInformation")),
        }),
      company_registration_number: Yup.string().optional().nullable(),
      youth_protection_officer: Yup.string().optional().nullable(),
    }),
    name: Yup.string()
      .when("company_type", {
        is: COMPANY_TYPE.NEW,
        then: schema => schema.required(t("media.NameCannotBeEmpty")),
        otherwise: schema => schema.optional().nullable(),
      }),
    email: Yup.string()
      .when("company_type", {
        is: COMPANY_TYPE.NEW,
        then: schema => schema.required().email(t("media.email_invalid")),
        otherwise: schema => schema.optional().nullable(),
      }),
    phone_number: Yup.string()
      .when("company_type", {
        is: COMPANY_TYPE.NEW,
        then: schema => schema.required(),
        otherwise: schema => schema.optional().nullable(),
      }),
    company_type: Yup.number().required().default(COMPANY_TYPE.NEW),
    domain_provider: Yup.string().optional().nullable(),
    domain_id: Yup.string().optional().nullable(),
    domain_password: Yup.string().optional().nullable(),
  });

  const removeEmptyFields = (obj: any): any => {
    const result: any = {};

    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          const nestedObject = removeEmptyFields(obj[key]);
          if (Object.keys(nestedObject).length > 0) {
            result[key] = nestedObject;
          }
        } else if (Array.isArray(obj[key])) {
          const filteredArray = obj[key]
            .map((item: any) =>
              typeof item === "object" ? removeEmptyFields(item) : item
            )
            .filter((item: any) => item !== null && item !== undefined);

          if (filteredArray.length > 0) {
            result[key] = filteredArray;
          }
        } else {
          result[key] = obj[key];
        }
      }
    }

    return result;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createPopupSchema,
    onSubmit: async (values) => {
      const params = { ...values } as any;
      if (
        (params.domain && params.domain === "https://") ||
        (params.domain && params.domain === "http://")
      ) {
        params.domain = null;
      }
      const [error, result] = await catchAsync(
        cmsHubMembershipApi.companiesCreate(removeEmptyFields(params)),
        setLoading
      );
      if (error) {
        typeof error === "string"
          ? toast.error(error)
          : formik.setErrors(error);
        return;
      }
      toast.success(result.message);
      refesh();
      return;
    },
  });

  const BUSSINESS = useMemo(() => {
    return business
      ?.filter((i) => i?.id != 1)
      ?.map((item: any) => ({
        value: item.id?.toString(),
        label: t(BUSSINESS_TEAM_LANG[item.id]),
      }));
  }, [business]);

  const layoutTypes = [
    {
      value: LAYOUT_TYPE.NEWS,
      label: t("media.news"),
    },
    {
      value: LAYOUT_TYPE.MAGAZINE,
      label: t("media.magazine"),
    }
  ]

  const optionRegisterType = [
    {
      value: COMPANY_TYPE.TRANSFER,
      label: t('media.service_transfer'),
    },
    {
      value: COMPANY_TYPE.NEW,
      label: t('media.new_creation'),
    }
  ]

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setTabModal("1")
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      centered={true}
      scrollable={false}
      style={{ width: "60%", maxWidth: "70%", minWidth: "850px" }}
    >
      <div className="d-flex justify-content-between pt-3 pb-2 px-3 items-center">
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {t("media.MediaRegistrationPresscon")}
        </div>
        <div onClick={() => onClose()}>
          <i
            className="ri-close-line"
            style={{ fontSize: "20px", cursor: "pointer" }}
          ></i>
        </div>
      </div>
      <div className="px-3">
        <Nav
          className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0 gap-3"
          role="tablist"
        >
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "1",
              })}`}
              onClick={() => {
                setTabModal("1");
              }}
            >
              {t("media.MediaInformation")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "2",
              })}`}
              onClick={() => {
                setTabModal("2");
              }}
            >
              {t("media.MemberInformation")}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <TabContent activeTab={tabModal} className="text-muted">
            <TabPane tabId={"1"} id={"tab-1"}>
              <div className="d-flex flex-column gap-3">
                <div style={{ fontSize: 15, lineHeight: "20px", fontWeight: 700 }}>
                  {t("media.media_information_settings")}
                </div>
                <div className="row">
                  <div className="col-6 d-flex flex-column gap-3">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        {t("media.business")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-8">
                        <DropdownOption
                          name="business_team_id"
                          dataList={BUSSINESS}
                          className="search-filter-category-type"
                          classNamePrefix="name-prefix"
                          initialValue={BUSSINESS?.find(
                            (i) => i?.value == formik?.values?.business_team_id
                          )}
                          onChangeSelect={(e: any) => {
                            if (e) {
                              formik.setFieldValue(
                                "business_team_id",
                                Number(e?.value)
                              );
                            }
                          }}
                          labelTop={""}
                        />
                        <FormFeedback>
                          {formik.values.business_team_id
                            ? formik.errors.business_team_id
                            : ""}
                        </FormFeedback>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        {t("media.MediaName")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-8">
                        <Input
                          type="text"
                          className="form-control"
                          {...formik.getFieldProps("company_name")}
                          placeholder={t("media.PleaseEnterTheNameOfEachItem")}
                          maxLength={50}
                          invalid={
                            !!(
                              formik.values.company_name &&
                              formik.errors.company_name
                            )
                          }
                        />
                        <FormFeedback>
                          {formik.values.company_name
                            ? formik.errors.company_name
                            : ""}
                        </FormFeedback>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        {t("media.subscription_type")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-8">
                        <div className="d-flex justify-content-start align-items-center gap-4 h-100">
                          {
                            optionRegisterType.map((item, index) => (
                              <div>
                                <input
                                  className="form-check-input me-2"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={`flexRadioDefault${index}`}
                                  checked={item?.value == formik?.values?.company_type}
                                  style={{ minWidth: "16px" }}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "company_type",
                                      item?.value
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label position-relative"
                                  htmlFor={`flexRadioDefault${index}`}
                                >
                                  {item?.label}
                                </label>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  formik?.values?.company_type === COMPANY_TYPE.NEW && (
                    <>
                      <div className="row" style={{ gap: "1rem 0" }}>
                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              {t("media.email")}
                              <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-8">
                              <Input
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps("email")}
                                placeholder={t("media.pls_email")}
                                maxLength={50}
                                invalid={
                                  !!(
                                    formik.values.email &&
                                    formik.errors.email
                                  )
                                }
                              />
                              <FormFeedback>
                                {formik?.values?.email
                                  ? formik?.errors?.email
                                  : ""}
                              </FormFeedback>
                              <div style={{ color: '#999', fontSize: '10px', whiteSpace: 'nowrap', marginTop: '5px' }}>초기 비밀번호는 ‘123456789A’ 입니다.</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">
                                  {t("media.MemberName")}
                                  <span className="text-danger"> *</span>
                                </label>
                                <div className="col-sm-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...formik.getFieldProps("name")}
                                    placeholder={t("media.pls_name")}
                                    maxLength={50}
                                    invalid={
                                      !!(
                                        formik.values.name &&
                                        formik.errors.name
                                      )
                                    }
                                  />
                                  <FormFeedback>
                                    {formik?.values?.name
                                      ? formik?.errors?.name
                                      : ""}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">
                                  {t("media.PhoneNumber")}
                                  <span className="text-danger"> *</span>
                                </label>
                                <div className="col-sm-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...formik.getFieldProps("phone_number")}
                                    placeholder={t("media.pls_phone")}
                                    maxLength={50}
                                    invalid={
                                      !!(
                                        formik.values.phone_number &&
                                        formik.errors.phone_number
                                      )
                                    }
                                  />
                                  <FormFeedback>
                                    {formik?.values?.phone_number
                                      ? formik?.errors?.phone_number
                                      : ""}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
                <div className="row">
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        {t("media.layout")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-8">
                        <DropdownOption
                          name="layout_type"
                          dataList={layoutTypes}
                          className="search-filter-category-type"
                          classNamePrefix="name-prefix"
                          initialValue={layoutTypes?.find(
                            (i) => i?.value == formik?.values?.layout_type
                          )}
                          onChangeSelect={(e: any) => {
                            if (e) {
                              formik.setFieldValue(
                                "layout_type",
                                e?.value
                              );
                            }
                          }}
                          labelTop={""}
                        />
                        <FormFeedback>
                          {formik.values.layout_type
                            ? formik.errors.layout_type
                            : ""}
                        </FormFeedback>
                        <div style={{ color: '#999', fontSize: '10px', whiteSpace: 'nowrap', marginTop: '5px' }}>
                          매체 등록 후에는 레이아웃 변경이 어려우니, 신중히 확인해 주시기 바랍니다.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                  </div>
                </div>
                {
                  formik?.values?.company_type === COMPANY_TYPE.TRANSFER && (
                    <>
                      <div style={{ fontSize: 15, lineHeight: "20px", fontWeight: 700 }}>
                        {t("media.footer_setting")}
                      </div>
                      <div className="row" style={{ gap: "1rem 0" }}>
                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              {t("media.RegistrationNumber")}
                              <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-8">
                              <Input
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps(
                                  "footer_configs.registration_number"
                                )}
                                placeholder={t("media.Placeholder_RegistrationNumber")}
                                maxLength={30}
                              />
                              <FormFeedback>
                                {formik?.values?.footer_configs?.registration_number
                                  ? formik?.errors?.footer_configs?.registration_number
                                  : ""}
                              </FormFeedback>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              {t("media.Editor")}
                              <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-8">
                              <Input
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps("footer_configs.chief_editor")}
                                placeholder={t("media.Placeholder_Editor")}
                                maxLength={30}
                              />
                              <FormFeedback>
                                {formik?.values?.footer_configs?.chief_editor
                                  ? formik?.errors?.footer_configs?.chief_editor
                                  : ""}
                              </FormFeedback>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              {t("media.Publisher")}
                              <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-8">
                              <Input
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps("footer_configs.publisher")}
                                placeholder={t("media.Placeholder_Publisher")}
                                maxLength={30}
                              />
                              <FormFeedback>
                                {formik?.values?.footer_configs?.publisher
                                  ? formik?.errors?.footer_configs?.publisher
                                  : ""}
                              </FormFeedback>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              {t("media.MainPhoneNumber")}
                              <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-8">
                              <Input
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps("footer_configs.main_phone")}
                                placeholder={t("media.Placeholder_MainPhoneNumber")}
                                maxLength={30}
                              />
                              <FormFeedback>
                                {formik?.values?.footer_configs?.main_phone
                                  ? formik?.errors?.footer_configs?.main_phone
                                  : ""}
                              </FormFeedback>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">
                              {t("media.Address")}
                              <span className="text-danger"> *</span>
                            </label>
                            <div className="col-sm-10">
                              <Input
                                type="text"
                                className="form-control"
                                maxLength={30}
                                {...formik.getFieldProps("footer_configs.address")}
                                placeholder={t("media.Placeholder_Address")}
                              />
                              <FormFeedback>
                                {formik?.values?.footer_configs?.address
                                  ? formik?.errors?.footer_configs?.address
                                  : ""}
                              </FormFeedback>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label whitespace-nowrap">
                              {t("media.BusinessRegistrationNumber")}
                            </label>
                            <div className="col-sm-8">
                              <Input
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps(
                                  "footer_configs.company_registration_number"
                                )}
                                placeholder={t(
                                  "media.Placeholder_BusinessRegistrationNumber"
                                )}
                                maxLength={30}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label whitespace-nowrap">
                              {t("media.YouthProtectionOfficer")}
                            </label>
                            <div className="col-sm-8">
                              <Input
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps(
                                  "footer_configs.youth_protection_officer"
                                )}
                                maxLength={30}
                                placeholder={t(
                                  "media.Placeholder_YouthProtectionOfficer"
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            </TabPane>
            <TabPane tabId={"2"} id={"tab-2"}>
              <div className="d-flex flex-column gap-3">
                <div style={{ fontSize: 15, lineHeight: "20px", fontWeight: 700 }}>
                  {t("media.setting_domain_information")}
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        {t("media.domain_purchase_company")}
                      </label>
                      <div className="col-sm-8">
                        <Input
                          type="text"
                          className="form-control"
                          {...formik.getFieldProps("domain_provider")}
                          maxLength={200}
                          placeholder={t('media.pls_domain_purchase_company')}
                          invalid={!!formik.errors.domain_provider}
                        />
                        <FormFeedback>{formik.errors.domain_provider}</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="col-6"></div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        ID
                      </label>
                      <div className="col-sm-8">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder={t('media.pls_domain_purchase_company_id')}
                          {...formik.getFieldProps("domain_id")}
                          maxLength={200}
                          invalid={!!formik.errors.domain_id}
                        />
                        <FormFeedback>{formik.errors.domain_id}</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        PW
                      </label>
                      <div className="col-sm-8">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder={t('media.pls_domain_purchase_company_password')}
                          {...formik.getFieldProps("domain_password")}
                          maxLength={200}
                          invalid={!!formik.errors.domain_password}
                        />
                        <FormFeedback>{formik.errors.domain_password}</FormFeedback>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        {t("media.DomainURL")}
                      </label>
                      <div className="col-sm-8">
                        <Input
                          type="text"
                          className="form-control"
                          {...formik.getFieldProps("domain")}
                          maxLength={200}
                          invalid={!!formik.errors.domain}
                        />
                        <FormFeedback>{formik.errors.domain}</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="col-6"></div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        {t("media.DomainStartDate")}
                      </label>
                      <div className="col-sm-8">
                        <DateTimePickerCustom
                          isNotFilterTime={true}
                          isNotshowTimeSelect={true}
                          className="w-100"
                          wrapperClassName="w-100"
                          placeholder="----:--:--"
                          dateFormat="yyyy-MM-dd"
                          date={formik.values?.domain_start_date}
                          onChange={(date) => {
                            formik.setFieldValue("domain_start_date", date);
                            if (
                              moment(date) > moment(formik.values?.domain_end_date)
                            ) {
                              formik.setFieldValue("domain_end_date", null);
                              return;
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label whitespace-nowrap">
                        {t("media.DomainExpirationDate")}
                      </label>
                      <div className="col-sm-8">
                        <DateTimePickerCustom
                          isNotFilterTime={true}
                          isNotshowTimeSelect={true}
                          className="w-100"
                          wrapperClassName="w-100"
                          dateFormat="yyyy-MM-dd"
                          placeholder="----:--:--"
                          date={formik.values?.domain_end_date}
                          onChange={(date) => {
                            formik.setFieldValue("domain_end_date", date);
                          }}
                          minDate={formik.values?.domain_start_date}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
          <div className="hstack gap-2 mt-5 justify-content-center">
            <button
              className="btn btn-soft-secondary fs-14"
              type="button"
              color="light"
              onClick={() => onClose()}
              style={{ width: "150px" }}
            >
              {t("cms_media.cancellation")}
            </button>
            <button
              type="submit"
              className="btn btn-primary fs-14"
              style={{ width: "150px" }}
              disabled={!formik.isValid || !formik.dirty}
            >
              {loading ? <Spinner size="sm me-2"></Spinner> : null}
              {t("cms_media.registration")}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalRegisterPresscon;
