import React, { useEffect, useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
//
import useQueryParams from "components/Hooks/useQueryParams";
import BreadCrumb from "components/Common/BreadCrumb";
import ServiceInquiry from "./Service";
import InquiryViaPhone from "./InquiryViaPhone";
import InquiryHomePage from "./HomePage";
import { catchAsync } from "helpers/general";
import inquiryApi from "api/inquiryApi";
import { toast } from "react-toastify";

const CmsHubMedia = () => {
  const { t } = useTranslation();

  const { clearQueryParams, setQueryParams, getQueryParams } = useQueryParams();
  const [customActiveTab, setcustomActiveTab] = useState(
    getQueryParams()?.tab?.toString() || "1"
  );

  const [summary, setSummary] = useState();
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      clearQueryParams();
      setcustomActiveTab(tab);
    }
  };

  const initData = async () => {
    const [error, result] = await catchAsync(inquiryApi.getSummary());
    if (error) {
      toast.error(error);
      return;
    }
    setSummary(result?.data);
    return;
  };

  const tabs = [
    {
      id: "1",
      label: t("inquiry.service_inquiry"),
      content: <ServiceInquiry />,
      route: "service_inquiry",
      keyTotal: "total_service",
    },
    {
      id: "2",
      label: t("inquiry.homepage_inquiry"),
      content: <InquiryHomePage />,
      route: "homepage_inquiry",
      keyTotal: "total_homepage",
    },
    {
      id: "3",
      label: t("inquiry.inquiry_via_phone"),
      content: <InquiryViaPhone refresh={initData} />,
      route: "inquiry_via_phone",
      keyTotal: "total_phone",
    },
  ];

  useEffect(() => {
    initData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb title={t("cms_media.media_manager_access")} pageTitle={'Presscon Ai'} /> */}
          <Nav tabs className="nav nav-tabs nav-tabs-custom mb-3">
            {tabs.map((tab) => (
              <NavItem key={tab.id}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === tab.id,
                  })}
                  onClick={() => {
                    toggleCustom(tab.id);
                    // clearQueryParams();
                  }}
                >
                  {tab.label} ({summary?.[tab?.keyTotal] || 0})
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={customActiveTab} className="text-muted">
            {tabs.map((tab) => (
              <TabPane tabId={tab.id} key={tab.id} id={`tab-${tab.id}`}>
                {customActiveTab === tab.id && tab.content}
              </TabPane>
            ))}
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CmsHubMedia;
